// Using a bundler like webpack
import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.css'

// TESTING BETTER ---
document.addEventListener('DOMContentLoaded', (event) => {
  console.log(GLightbox)

  const imgs = [...document.querySelectorAll('.glightbox')]
  console.log(imgs)

  const lightbox = GLightbox({
    selector: '.glightbox',
    touchNavigation: true,
    keyboardNavigation: true,
    loop: true,
  })
  lightbox.on('open', () => {
    console.log('open')
  })
})
